export const required = (value) =>
  !value || (value && value.trim() === '') ? 'Required' : undefined;

export const email = (value) =>
  value &&
  !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  )
    ? 'Invalid email address'
    : undefined;

export const textValidation = (field_name, value) =>
    value &&
    !/^([A-ZÀ-ÿ][-,a-z. ']+[ ]*)+$/gim.test(
        value
    )
        ? 'Invalid '+field_name
        : undefined;

export const cardNumber = (value, sku) => {
    if (typeof value !== 'undefined') {

        const regexPattern = {
            MASTERCARD: /^5[1-5][0-9]{1,}|^2[2-7][0-9]{1,}$/,
            VISA: /^4[0-9]{2,}$/
        };
        let card_type = '';
        if(sku === 'P_VISA'){
            delete regexPattern.MASTERCARD;
            card_type = 'VISA ';
        } else if (sku === 'P_MC'){
            delete regexPattern.VISA;
            card_type = 'MasterCard ';
        }

        if(value.length !== 16){
            return "Invalid "+card_type+" Card Number";
        }
        for (const card in regexPattern) {
            if (value.replace(/[^\d]/g, "").match(regexPattern[card])) {
                if (value) {
                    return value &&
                    !/^[1-6]{1}[0-9]{14,15}$/i.test(
                        value.replace(/[^\d]/g, "").trim()
                    )
                        ? "Invalid "+card_type+" Card Number"
                        : undefined;
                }
            }
        }
        return "Invalid "+card_type+" Card Number";
    }
}

export const expiryValidation = (expiry_date) => {
    if (typeof expiry_date !== 'undefined'  ) {
        if(expiry_date.length === 7){
            var eDate = expiry_date.split("/");
            eDate[0] =  eDate[0].replace("_","")
            if (eDate[0].length === 1) {
                eDate[0] = "0"+eDate[0]
            }
            if (eDate[0] > 12 || eDate[0] < 1){
                return "Invalid Card Expiry Date";
            }
            const userDate = new Date(eDate[0]+'/'+new Date(eDate[1], eDate[0],0).getDate().toString()+'/'+eDate[1]); //current month should also be valid
            const currentDate = new Date();
            let difference = Math.abs(currentDate - userDate);
            var InYears = difference/(1000*60*60*24*365);
            if (!(userDate instanceof Date && !!userDate.getDate()) || (userDate <= currentDate || InYears >= 12)){  //input mask library leaves an "_" for a blank field
                return "Invalid Card Expiry Date";
            }
        }
    }
}

export const postal_code = (value) =>
    value &&
    !/^[A-Z]\d[A-Z]\d[A-Z]\d$/.test(
        value
    )
        ? `Please enter the postal code in upper case. For E.g. M1M1M1`
        : undefined;

export const minLength = (min) => (value) =>
  value && value.length < min
    ? `Must be at least ${min} characters `
    : undefined;

export const maxLength = (max) => (value) =>
    value && value.length > max
        ? `Must not be more than ${max} characters `
        : undefined;


export const matchLength = (max) => (value) =>
    value && value.length === max
        ? `Must be ${max} characters `
        : undefined;

export const phoneValidation = (value) =>
    value &&
    !/^(\+\d{1,2}\s?)?1?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(
        value
    )
        ? 'Invalid phone number'
        : undefined;
