import React  from "react";
import { InputAdornment,
  IconButton} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputMask from "react-input-mask";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from '@mui/material/CardMedia';
import CardContent from "@mui/material/CardContent";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";
import queryString from "query-string";
import { Field, reduxForm, SubmissionError} from "redux-form";
import { connect } from "react-redux";
import { compose } from "redux";
import {addCredit, updateAccount} from "../../store/actions";
import { getProcessing, getError } from "../../store/selectors";
import {
    maxLength,
    required,
    cardNumber,
    expiryValidation,
    postal_code,
    phoneValidation, email
} from "../../utils/formValidator";
import {act} from "react-dom/test-utils";

window.currency = "";
window.sku = "";
const styles = (theme) => ({
  access_denied: {
    borderWidth: 1,
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    borderRadius: 5,
  },
  backdrop: {
    position: "absolute",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
    position: "relative",
    zIndex: 0,
  },
  outlined: {
    borderWidth: 1,
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    borderRadius: 5,
  },
  root: {
    color: theme.palette.secondary.main,
  },
});

class CreditCardForm extends React.Component {


  // Constructor
  constructor(props) {
    super(props);

    this.state = {
      app_access: true,
      province_list: [],
      currency: "",
      sku: "",
      appId: "",
      cust_id: "",
      bin_web_state: true,
      bin_api_state: false,
      email_required: false,
      MONERIS: false,
      VISA: false,
      supplier_error_message: "",
      open: false,
      form_message: "",
      pan: "",
      showPan: false,
      maskedPan: "",
      actualPan: ""
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.changePan = this.changePan.bind(this);
  }

  onChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  onPhoneChange = (value) => {
    this.setState({
      phone: value,
    });
  };
  handleChange = (selectedOptions, actionMeta) => {
    const inputName = actionMeta.name;
    let selectedValues;

    if (Array.isArray(selectedOptions)) {
      selectedValues = selectedOptions.map((option) => option.value);

      selectedValues = selectedOptions.map((option) => option.value).join(",");
    } else {
      selectedValues = [selectedOptions.value];

      selectedValues = selectedOptions.value;
    }
    this.setState({
      [inputName]: selectedValues,
    });
  };


 changePan = (event) => {
    const { name, value } = event.target;
    const actualstatePan = this.state.actualPan;
    const prevValue = actualstatePan.replace(/ /g,'');
    const newValue = value.replace(/ /g,'');
    let actual = actualstatePan.replace(/ /g,'');

    if (newValue.length > 16){
      return;
    }
    if(newValue.length === 16 && prevValue.length === 0){
        let newChar = newValue;
        actual = `${newChar}`;
    }
    else if(newValue.length > prevValue.length){
      let newChar = newValue.split("").pop();
      actual = `${actual}${newChar}`;
    }else{
      const charsRemovedCount = prevValue.length - newValue.length;
      actual = actual.substr(0, actual.length - charsRemovedCount);
    }


    this.setState({
      actualPan : this.formatMask(actual),
      maskedPan: this.starredMask(actual)
    });
     let actualPan = this.formatMask(actual);
     window.pan = actualPan.replace(/ /g,'');
 };
    formatMask = function(value) {
        var output = [];
        for(var i = 0; i < value.length; i++) {
            if(i !== 0 && i % 4 === 0) {
                output.push(" "); // add the separator
            }
            output.push(value[i]);
        }
        return output.join("");
    };

  starredMask(value){
      var output = [];
      for(var i = 0; i < value.length; i++) {
          if(i !== 0 && i % 4 === 0) {
              output.push(" "); // add the separator
          }
          output.push(value[i]);
      }
      return output.join("").replace(/[\d]/g, "#");
  }

  componentDidMount() {
    let queryStrObj = queryString.parse(this.props.location.search);
    if(typeof  queryStrObj.refId === 'undefined'){
      this.setState({app_access: false});
      return;
    }

    fetch("/api/v1/credit/credit_polling/" + queryStrObj.refId)
      .then((res) => res.json())
      .then((json) => {
        if(json.status !== 0 && (json.data.status === "IFRAME_LOADED" || json.data.status === "INITIALIZE")){

          this.setState({
            currency: json.data.currency,
            sku: json.data.card_sku,
            appId: json.data.appId,
            cust_id: json.data.cust_id,
            bin_api_state: json.data.bin_validation,
            bin_validation:  json.data.bin_validation,
            virtual_agreement_signed: json.data.virtual_agreement_signed,
            refId: queryStrObj.refId
          });

          if(
              json.data.bin_validation === true &&
              json.data.virtual_agreement_signed === true &&
              json.data.currency === "CAD" &&
              json.data.card_sku === "P_VISA"
          ){
              this.setState({
                  bin_web_state: false,
              });
          }

          window.sku = json.data.card_sku;
          window.currency = json.data.currency;
          window.refID = queryStrObj.refId;
          this.props.updateAccount(window.refID, "IFRAME_LOADED").then(() => {
            if (this.props.errorMessage) {
              throw new SubmissionError({ _error: this.props.errorMessage });
            }
          });
          fetch("/api/v1/credit/provinces")
              .then((res) => res.json())
              .then((json) => {
                this.setState({
                  province_list: json.data,
                });
              });
        }else{
          this.setState({app_access: false});
        }

      });


  }
  onChangeMoneris = () => {
    this.setState((prevState) => ({
      MONERIS: !prevState.MONERIS,
    }));
  };

  onChangeVisa = () => {
      this.state.email_required = false;
    if(this.state.VISA === false){
        this.state.email_required = true;
    }
    this.setState((prevState) => ({
      VISA: !prevState.VISA,
    }));
  };

  onSubmit = (formValues) => {
    var eDate = formValues.expiry_date.split("/")
    eDate[0] = eDate[0].replace("_","")  // input mask library leaves an "_" for a blank field
    if (eDate[0].length < 2) {
      eDate[0] = "0"+eDate[0]           
    }
    eDate = eDate.join("/");
    formValues.expiry_date = eDate;
    if (
        this.state.MONERIS === false &&
        this.state.VISA === false
    ) {
      this.setState({
        supplier_error_message: "Atleast one type of supplier must be selected",
      });
      return false;
    }

    this.setState({open: true});
    const ignore_state = [
        "app_access",
      "undefined",
      "supplier_error_message",
      "province_list",
      "open",
      "form_message",
    ];

    this.props.updateAccount(window.refID, "SUBMITTED").then(() => {
      if (this.props.errorMessage) {

        throw new SubmissionError({ _error: this.props.errorMessage });
      }
    });
    Object.entries(this.state).map(function (obj) {
      if (!ignore_state.includes(obj[0])) {
        formValues[obj[0]] = obj[1];
      }
    });

    return this.props.addCredit(formValues).then(() => {
      if (this.props.errorMessage) {
        throw new SubmissionError({ _error: this.props.errorMessage });
      }
    });
  };

  handleClickShowPan = (event) => {
    this.setState({
      showPan: !this.state.showPan,
    });
  };



  handleMouseDownPan = (event) => {
    event.preventDefault();
  };

  supplierForm = (changedValue) => {
    changedValue =  window.pan;

    this.setState({
      pan:  window.pan
    });
    let cc_number = parseInt(changedValue.replaceAll(" - ", ""));
    if (
      cc_number.toString().length === 16 &&
      this.state.bin_api_state !== false
    ) {
      fetch("/api/v1/credit/bin_range/" + cc_number)
        .then((res) => res.json())
        .then((json) => {
          if (
            json.data !== null &&
            typeof this.state.bin_api_state !== "undefined" &&
            this.state.bin_api_state !== false &&
            this.state.currency === "CAD" &&
            this.state.sku === "P_VISA" && this.state.virtual_agreement_signed === true
          ) {
            this.setState({
              bin_web_state: false,
            });
          } else {
            this.setState({
              bin_web_state: true,
            });
          }
        });

    } else {
      this.setState({
        bin_web_state: true,
      });
    }
  };

  renderInputMaskField =({input, label, meta:{touched, error}})=>(
    <InputMask mask="99/9999"
         value={this.state.expiry_date}
               name="expiry_date"
               beforeMaskedValueChange={this.beforeMaskedValueChange}
               type="tel"
               required
               {...input}
    >
      {(inputProps) =>
        <TextField
            {...inputProps}
            label={label}
                  error={touched && !!error}
                   helperText={touched && error}
                  variant="outlined"
                  margin="none"
                  autoComplete="off"
                  size="small"
                  required
                  fullWidth
        />}

    </InputMask>
  )


  renderTextField = ({ input, value, label, meta: { touched, error }, ...custom }) => (
    <TextField
      label={label}
      value={value}
      error={touched && !!error}
      helperText={touched && error}
      variant="outlined"
      margin="none"
      autoComplete="off"
      size="small"
      required
      fullWidth
      {...input}
      {...custom}
    />
  );

  renderSelectGroup = ({
    input,
    labelId,
    label,
    name,
    id,
    options,
    meta: { touched, error },
    ...custom
  }) => {
    return (
      <FormControl required fullWidth size="small">
        <InputLabel id={labelId}>{label}</InputLabel>
        <Select
          labelId={labelId}
          name={name}
          id={id}
          value=""
          label={label}
          {...input}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {options.map((item, j) => {
            return (
              <MenuItem key={j} value={item.code}>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText error={touched && !!error}>
          {touched && error}
        </FormHelperText>
      </FormControl>
    );
  };

  render() {
    const {
      classes,
      handleSubmit,
      pristine,
      submitting,
      valid,
      error
    } = this.props;
    const {
      currency,
      sku,
      bin_web_state,
      supplier_error_message,
      open,
      app_access,
      showPan
    } = this.state;

    return (
      <Container component="main" maxWidth="md">
        <CssBaseline />
        {app_access !== true ? (
        <Box className={classes.paper} >
          <Box className={classes.form}>
          <Grid
              container
              spacing={0}
              align="center"
              justify="center"
          >
            <Grid item xs={12}
                  className={classes.access_denied}>
              <Card>
                <CardMedia
                    component="img"
                    sx={{ maxWidth: '30%', mt: 3 }}
                    image="/assets/error.svg"
                    alt="Error"
                />
                <CardContent>
                  <Typography variant="h4" color="primary">Access Denied</Typography>
                  <Typography variant="body">We could not find the page you were looking for.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          </Box>
        </Box>
        ) : (
        <Box className={classes.paper}>
          <Box
            component="form"
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(this.onSubmit)}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={1}>
              <Grid item sx={{ display: { xs: "none" } }}>
                <TextField
                  required
                  id="currency"
                  name="currency"
                  value={currency ?? ""}
                />
                <TextField id="sku" name="sku" value={sku ?? ""} />
              </Grid>
              <Grid item xs={12}>
                <Grid item component="fieldset" className={classes.outlined}>
                  <FormLabel
                    id="currency-label"
                    component="legend"
                    sx={{ px: 1 }}
                  >
                    Currency
                  </FormLabel>
                  <RadioGroup
                    row
                    value={currency ?? ""}
                    aria-labelledby="currency-label"
                    name="currency"
                  >
                    <FormControlLabel
                      value="CAD"
                      name="currency"
                      control={
                        <Radio
                          onChange={this.onChange}
                          color="primary"
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 40,
                              color: "#1a4e75",
                            },
                          }}
                        />
                      }
                      label="CAD"
                      readOnly={true}
                      disabled={true}
                      classes={{ label: classes.label }}
                    />
                    <FormControlLabel
                      value="USD"
                      name="currency"
                      control={
                        <Radio
                          onChange={this.onChange}
                          color="primary"
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 40,
                              color: "#1a4e75",
                            },
                          }}
                        />
                      }
                      label="USD"
                      readOnly={true}
                      disabled={true}
                      classes={{ label: classes.label }}
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
              <Grid item xs={8}>
                {
                  showPan ?
                      (<Field
                          id="pan"
                          label="Card Number"
                          name="pan"
                          type="text"
                          component={this.renderTextField}
                          onChange={ this.changePan }
                          onBlur={ (event) => this.supplierForm(event) }
                          InputProps={{
                            value: this.state.actualPan,
                            endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={this.handleClickShowPan}
                                      onMouseDown={this.handleMouseDownPan}
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                </InputAdornment>
                            )
                          }}
                      />)
                      :
                      (<Field
                          id="masked_pan"
                          label="Card Number"
                          name="masked_pan"
                          type="text"
                          component={this.renderTextField}
                          onChange={this.changePan }
                          onBlur={ (event) => this.supplierForm(event) }
                          InputProps={{
                            value : this.state.maskedPan,
                            endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                      aria-label="toggle pan visibility-off"
                                      onClick={this.handleClickShowPan}
                                      onMouseDown={this.handleMouseDownPan}
                                  >
                               <VisibilityOffIcon />
                                  </IconButton>
                                </InputAdornment>
                            )
                          }}
                      />)
                }

              </Grid>
              <Grid item xs={4}>
                  <Field id="expiry_date" label="Card Expiry (MM/YYYY)" name="expiry_date" type="tel"
                        onChange={this.onChange}
                    component={this.renderInputMaskField}
                  />
               </Grid>
              <Grid item xs={12}>
                <Field
                  id="cardholder"
                  label="Name on Card"
                  name="cardholder"
                  component={this.renderTextField}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  autoComplete="nickname"
                  id="nickname"
                  label="Card Nickname"
                  name="nickname"
                  component={this.renderTextField}
                />
              </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Card sx={{ display: "flex" }}>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <CardContent sx={{ flex: "1 0 auto" }}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.MONERIS}
                                  name="moneris_checkbox"
                                  onChange={this.onChangeMoneris}
                                  color="primary"
                                  sx={{
                                    "& .MuiSvgIcon-root": {
                                      fontSize: 40,
                                    },
                                  }}
                                />
                              }
                              label="Pay Non-Accepting Supplier"
                            />
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              component="p"
                              align="justify"
                            >
                                Use your credit card to fund payments to suppliers that do not accept credit card payments
                            </Typography>
                          </CardContent>
                        </Box>
                      </Card>
                    </Grid>
                    <Grid item xs={6}>
                      <Card sx={{ display: "flex" }}>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <FormControl
                            required
                            error={error}
                            component="fieldset"
                            variant="standard"
                          >
                            <CardContent sx={{ flex: "1 0 auto" }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="visa_checkbox"
                                    disabled={bin_web_state}
                                    readOnly={bin_web_state}
                                    checked={this.state.VISA}
                                    onChange={this.onChangeVisa}
                                    color="primary"
                                    sx={{
                                      "& .MuiSvgIcon-root": {
                                        fontSize: 40,
                                      },
                                    }}
                                  />
                                }
                                label="Pay Accepting Supplier"
                              />
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                component="p"
                                align="justify"
                              >
                                  Use your credit card to pay suppliers that accept credit card payments
                              </Typography>
                            </CardContent>
                          </FormControl>
                        </Box>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <FormHelperText style={{ color: "#d32f2f" }}>
                        {supplier_error_message}
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </Grid>
              <Grid item xs={12}>
                <Typography component="h2" variant="h6" color="primary">
                  Personal Details
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <Field
                      id="street_number"
                      label="Street Number"
                      name="street_number"
                      component={this.renderTextField}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Field
                      id="street_name"
                      label="Street Name"
                      name="street_name"
                      component={this.renderTextField}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Field
                  id="city"
                  label="City"
                  name="city"
                  component={this.renderTextField}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  id="province"
                  labelId="province"
                  label="Province"
                  name="province"
                  required={true}
                  component={this.renderSelectGroup}
                  onChange={this.handleChange}
                  options={this.state.province_list}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                    id="postal_code"
                    label="Postal Code"
                    name="postal_code"
                    component={this.renderTextField}
                />
              </Grid>
                {this.state.email_required ? (

                    <Grid item xs={12}>

                        <Field
                            id="email"
                            label="Email Address"
                            name="email"
                            component={this.renderTextField}
                            required={true}
                        />
                    </Grid>
                ): (
                    <Grid item sx={{ display: { xs: "none" } }}></Grid>
                )}
              <Grid item xs={12}>

                <Field
                    id="phone"
                    label="Mobile Number"
                    name="phone"
                    component={this.renderTextField}
                    required={false}
                />
              </Grid>

              <Grid item xs={6}>
                <Button
                  className={classes.submit}
                  color="primary"
                  disabled={pristine || submitting || !valid}
                  fullWidth
                  type="submit"
                  variant="contained"
                >
                  Submit
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  className={classes.submit}
                  color="secondary"
                  fullWidth
                  variant="contained"
                  onClick={e => {this.setState({open: true}); this.props.updateAccount(window.refID, "CANCELLED")}}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Backdrop
              className={classes.backdrop}
              sx={{ color: '#1a4e75', backgroundColor: 'rgba(255,255,255,0.9)',  zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
          >
            <CircularProgress  size="4rem"  color="inherit" />
          </Backdrop>
        </Box>
        )}
      </Container>
    );
  }
}

const maptStateToProps = (state) => {
  return {
    isProcessing: getProcessing(state),
    errorMessage: getError(state),
  };
};



const validate = (values) => {
  const errors = {};
  errors.masked_pan =
    cardNumber(window.pan, window.sku) ||
    required(window.pan) ;
  errors.email = email(values.email) || required(values.email);
  errors.pan =
        cardNumber(window.pan, window.sku) ||
        required(window.pan) ;
  errors.expiry_date =
    expiryValidation(values.expiry_date) || required(values.expiry_date);
  errors.cardholder =
    required(values.cardholder) || maxLength(100)(values.cardholder);
  errors.nickname = required(values.nickname) || maxLength(25)(values.nickname);
  errors.street_number =
    required(values.street_number) || maxLength(19)(values.street_number);
  errors.street_name =
    required(values.street_name) || maxLength(40)(values.street_name);
  errors.city = required(values.city) || maxLength(20)(values.city);
  errors.province = required(values.province);
  errors.postal_code =
    postal_code(values.postal_code) || required(values.postal_code) || maxLength(6)(values.postal_code);
  errors.phone = phoneValidation(values.phone);
  return errors;
};

export default compose(
  connect(maptStateToProps, {addCredit, updateAccount}),
  reduxForm({ form: "addCredit", destroyOnUnmount: false, validate }),
  withStyles(styles)
)(CreditCardForm);
